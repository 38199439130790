.f3cc {
  --f3cc-background-color: #f0ed74;
  --f3cc-accent-color: #f05b61;
}

#feincms3-cookiecontrol .btn {
  display: inline-block;
  background: var(--f3cc-accent-color);
  color: #fff;
  padding: 0.8rem;
  white-space: nowrap;
}

#feincms3-cookiecontrol .btn:hover {
  opacity: 0.7;
}

#feincms3-cookiecontrol .btn-panel,
#feincms3-cookiecontrol .btn-cancel {
  color: #000;
  background: #fff;
}
