@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Roboto;
    font-weight: normal;
    src: url(/fonts/Roboto-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: Roboto;
    font-weight: bold;
    src: url(/fonts/Roboto-Bold.ttf) format("truetype");
  }
  @font-face {
    font-family: Poppins;
    src: url(/fonts/Poppins-BoldItalic.ttf) format("truetype");
  }
}

@layer components {
  /* Font customization */
  h1 {
    @apply text-center;
    @apply max-w-full;
    @apply overflow-hidden;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h6 {
    font-family: Poppins;
  }
}

@layer utilities {
  /* Menu-toggle */
  .menu-is-open {
    @apply overflow-hidden;
  }
  .mobile-menu {
    @apply transition-transform;
    transform: translateY(min(-720px, -100vh));
  }
  .menu-is-open .mobile-menu {
    transform: translateY(0);
  }

  .embedded-video__container > div {
    @apply relative;
    @apply h-0;
    @apply overflow-hidden;
    padding-bottom: 56.6615620214%;
  }

  .embedded-video__container > div iframe {
    @apply absolute;
    @apply top-0;
    @apply left-0;
    @apply w-full;
    @apply h-full;
  }
}

/* Icons */
i {
  @apply w-4;
  @apply h-4;
  @apply block;
}

svg.MuiSvgIcon-root {
  @apply w-full;
  @apply h-full;
}

/* form overrides, not getting purged */
.prose form input:not([type="file"], [type="submit"]),
.prose form select,
.prose form textarea {
  @apply border;
  @apply border-gray;
  @apply rounded-md;
  @apply p-2;
  @apply max-w-full;
}

.prose form input[type="checkbox"] + label,
.prose form input[type="file"] {
  @apply cursor-pointer;
}

/* .prose form label {
} */
.prose form .errorlist li {
  @apply text-red;
}
.prose form .errors h3 {
  @apply text-red;
}

.prose p em {
  @apply text-black;
}

/* FAQ, thx safari */
details > summary::-webkit-details-marker {
  display: none;
}

details .show-when-open {
  @apply hidden;
}
details[open] .show-when-open {
  @apply flex;
}
details[open] .hide-when-open {
  @apply hidden;
}

details p {
  @apply md:mx-8;
  @apply text-black;
}

/* Testimonials */
.active-dropdown {
  scroll-margin: 33vh;
}

/* Donations */
#lema-container.lema-container .lema-amount-box.lema-active {
  @apply bg-red;
}
